import firebase from 'firebase';

const config = {
  apiKey: 'AIzaSyCtKyVAsejMJYfwKEOCndplTlnWa74lS7A',
  authDomain: 'codex-maria-clara.firebaseapp.com',
  projectId: 'codex-maria-clara',
  storageBucket: 'codex-maria-clara.appspot.com',
  messagingSenderId: '909231407327',
  appId: '1:909231407327:web:44cd217215d6ad724da96a',
  measurementId: 'G-0ZVWMM6QGE',
};

firebase.initializeApp( config );

export const auth = firebase.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();
export const timeStamp = firebase.firestore.Timestamp;
export type TimeStamp = firebase.firestore.Timestamp;
export const fieldValue = firebase.firestore.FieldValue;
export type FieldValue = firebase.firestore.FieldValue;
export const authPersistenceLocal = firebase.auth.Auth.Persistence.LOCAL;
export const authPersistenceSession = firebase.auth.Auth.Persistence.SESSION;
export const emailAuthProvider = firebase.auth.EmailAuthProvider;
